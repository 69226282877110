<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>공지 사항</h3>
      </div>
      <div class="my-3 card">
        <mdb-tbl reponsive>
          <mdb-tbl-head style="background-color: #ececec;">
            <tr>
              <th colspan="4" style="font-size: 20px;">{{ title }}</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr>
              <th>등록일</th>
              <td colspan="3">{{ reg_date }}</td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{{ writer }}</td>
              <th>조회수</th>
              <td>{{ views }}</td>
            </tr>
            <tr>
              <th style="vertical-align: middle">파일</th>
              <td colspan="3">
                <ul>
                  <li v-if="file_name !== ''">
                    <a :href="file_name"> {{ file_url }}</a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: center">
                <div v-if="image_url !== ''">
                  <img :src="image_url" alt="img" class="image_center">
                </div>
                <div class="my-3" v-if="contents !== ''">
                  {{ contents }}
                </div>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </mdb-col>
    <mdb-col class="mb-5 text-right" style="text-align: center">
      <router-link :to="{name : 'adminNoticeManageEdit', query: {id: id}}">
        <mdb-btn outline="primary" small icon="edit">수정</mdb-btn>
      </router-link>
      <mdb-btn outline="danger" small icon="trash" @click="deletePost">삭제</mdb-btn>
    </mdb-col>

  </mdb-container>
</template>

<script>
import {mdbBtn, mdbCol, mdbContainer, mdbTbl, mdbTblBody, mdbTblHead} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "NoticeManageView",
  data() {
    return {
      id: this.$route.query.id,
      contents: '',
      files: [],
      image_url: '',
      order_number: 0,
      reg_date: '',
      title: '',
      views: 0,
      writer: '',
      file_name: '',
      file_url: '',
    }
  },
  components: {
    mdbBtn,
    mdbContainer,
    mdbTblHead,
    mdbCol,
    mdbTbl,
    mdbTblBody
  },
  mounted() {
    this.onFirebaseLoadData();
  },
  methods: {
    onFirebaseLoadData() {
      const db = firebase.firestore();
      const self = this;

      db.collection("notice")
          .doc(self.id).get().then(function (querySnapshot) {
        const _data = querySnapshot.data();
        self.contents = _data.contents;
        if (!self.isEmpty(_data.files)) {
          self.files = Object.entries(_data.files);
          self.file_url = Object.entries(_data.files)[0][0];
          self.file_name = Object.entries(_data.files)[0][1];
        }
        self.image_url = _data.image_url;
        self.order_number = _data.order_number;
        const date = new Date(_data.reg_date.seconds * 1000);
        self.reg_date = self.getParseDate(date);
        self.title = _data.title;
        self.views = _data.views;
        self.writer = _data.writer;
      });
    },
    deletePost() {
      const self = this;
      const confirmDialog = confirm('정말로 삭제하시겠습니까?');
      if (!confirmDialog) {
        return;
      }

      const db = firebase.firestore();
      const docRef = db.collection("notice");
      docRef.doc(self.id)
          .delete()
          .then(() => {
            this.$router.replace('/admin/noticeManageList').catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },

    isEmpty(param) {
      return Object.keys(param).length === 0;
    },
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    }
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

th {
  background-color: #ececec;
}

.image_center {
  max-width: 100%;
  margin: 0 auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>